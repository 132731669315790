<template>
  <div>
    <v-btn
      v-for="(item, i) in items.slice(0, large ? items.length : 2)"
      :key="i"
      fab
      class="ma-2"
      color="info"
      :small="!large"
      @click="buttonlink(item.link)"
    >
      <v-icon
        dark
        v-text="item.icon"
      />
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      large: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      items: [
        {
          name: 'Phone',
          icon: 'mdi-phone',
          link: 'tel:+1402-630-0968',
        },
        {
          name: 'Email',
          icon: 'mdi-email',
          link: 'mailto:admin@streamlinepainting.biz',
        },
        {
          name: 'Facebook',
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/streamlinepainting2020',
        },
      ],
    }),
    methods: {
      buttonlink (link) {
        window.location = link
      },
    },
  }
</script>
